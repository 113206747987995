import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AdminMenu from '../components/AdminMenu';
import VerificationCard from '../components/VerificationCard';
import type { Verification, Verifications } from '../model/models';
import { checkAuth, fetchVerifications } from '../utils/fetch';

const Verify = () => {
  const [verification, setVerifications] = useState<Verifications | null>(null);
  const [verFilter, setVerFilter] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    fetchVerifications().then(
      (items) => {
        // setIsLoaded(true);
        setVerifications(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    checkAuth().then((result) => {
      if (!result) {
        const nextPage = new URLSearchParams(location.search).get('next');
        if (nextPage) {
          window.location.href = nextPage;
        } else {
          window.location.href = '/login?next=%2Fadmin%2Fverify';
        }
      }
    });
  }, []);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerFilter(event.target.value.trim());
  };

  function fnFilter(v: Verification) {
    return (
      !verFilter ||
      v.id.toLowerCase().startsWith(verFilter.toLowerCase()) ||
      v.firstname.toLowerCase().startsWith(verFilter.toLowerCase()) ||
      v.lastname.toLowerCase().startsWith(verFilter.toLowerCase())
    );
  }

  return (
    <div className="admin">
      <AdminMenu />
      <h2>Verify</h2>
      <p>Here you can verify a payment.</p>
      <div>
        <input
          type="text"
          name="filter"
          placeholder="filter"
          maxLength={64}
          onChange={handleChangeFilter}
          style={{
            padding: 10,
            backgroundColor: '#fbfbfb',
            maxWidth: '80%',
          }}
        />
      </div>

      <h3>
        not yet verified (
        {verification?.data.filter((v) => !v.verified).length ?? 0})
      </h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {verification?.data
          .filter((v) => !v.verified)
          .filter((v) => fnFilter(v))
          .map((v) => (
            <VerificationCard key={v.id} verification={v} />
          ))}
      </div>
      <h3>
        already verified (
        {verification?.data.filter((v) => v.verified).length ?? 0})
      </h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {verification?.data
          .filter((v) => v.verified)
          .filter((v) => fnFilter(v))
          .map((v) => (
            <VerificationCard key={v.id} verification={v} />
          ))}
      </div>
    </div>
  );
};

export default Verify;
