import config from '../config';
import type { Selection, Weight } from '../model/models';

export function clickedWildcard(
  weightclass: Weight,
  seedSelected: number,
  bout: number,
  selectedList: Selection,
  setSelectedList: any
) {
  if (
    seedSelected === 0 ||
    (config.BRACKET_SIZE === 33 && bout > 180) ||
    (config.BRACKET_SIZE === 16 && bout > 260)
  ) {
    return;
  }
  const newSelectedList = {
    ...selectedList,
    wc: {
      weight: weightclass,
      seed: seedSelected,
    },
  } as Selection;
  setSelectedList(newSelectedList);
  localStorage.setItem('selectionWcWeight', weightclass.toString());
  localStorage.setItem('selectionWcSeed', seedSelected.toString());
}
