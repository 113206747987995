import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid } from '@mui/material';
import { useState } from 'react';

import { weightclasses } from '../model/arrays';
import type { Verification, Weight } from '../model/models';
import { epochToLocale } from '../utils/epochToHuman';
import { putNickname, putVerify } from '../utils/fetch';

const WeightCell = (props: {
  weight: Weight | string;
  pick: number | string;
}) => {
  return (
    <Grid container direction="column" border={1} width={'70px'}>
      <Grid item>{props.weight}</Grid>
      <Grid item>#{props.pick}</Grid>
    </Grid>
  );
};

type Props = {
  verification: Verification;
};

const VerificationCard = (props: Props) => {
  const [adminName, setAdminName] = useState(
    props.verification.adminAssignedName ?? ''
  );
  const [verified, setVerified] = useState(props.verification.verified);

  const lbs = [
    props.verification.lbs125,
    props.verification.lbs133,
    props.verification.lbs141,
    props.verification.lbs149,
    props.verification.lbs157,
    props.verification.lbs165,
    props.verification.lbs174,
    props.verification.lbs184,
    props.verification.lbs197,
    props.verification.lbs285,
  ];

  const date = props.verification.dateAddedTimestamp
    ? epochToLocale(props.verification.dateAddedTimestamp)
    : new Date();

  function handleClick() {
    const newValue = !verified;
    const res = putVerify(props.verification.id, newValue);
    res.then(
      (result) => {
        // Check if the result is an error object
        if (result instanceof Error) {
          // Check if the error message includes the HTTP status code 422
          if (result.message.includes('422')) {
            // Handle error for HTTP status code 422
            window.location.href = '/login?next=%2Fadmin%2Fverify';
          }
          // Handle other error cases
          else {
            console.error(result.message);
          }
          return false;
        }
        setVerified(newValue);
        return true;
      },
      (_error) => {
        // setIsLoaded(true);
        return false;
        // setError(error);
      }
    );
  }

  function handleType(event: React.ChangeEvent<HTMLInputElement>) {
    setAdminName(event.target.value);
    putNickname(props.verification.id, event.target.value);
  }

  return (
    <Grid
      container
      direction="row"
      sx={{
        border: 2,
        padding: 1,
        marginTop: 1,
        marginBottom: 1,
        width: '870px',
      }}
    >
      <Grid item container direction="column">
        <Grid container sx={{ fontWeight: 'bold', height: 46 }} spacing={3}>
          <Grid item>{props.verification.id}</Grid>
          <Grid item>
            {props.verification.firstname} {props.verification.lastname}
          </Grid>
          <Grid item sx={{ grow: 1 }}>
            <input
              type="text"
              value={adminName}
              placeholder="admin-internal nickname"
              onChange={(event) => handleType(event)}
              minLength={2}
              maxLength={128}
              pattern="[a-zA-Z]+"
            />
          </Grid>
          <Grid item>{props.verification.altContact}</Grid>
          <Grid item sx={{ fontWeight: 'regular' }}>
            {date.toLocaleString('default', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Grid>
        </Grid>
        <Grid container direction="row" sx={{ marginTop: 1 }}>
          <Grid item>
            <Button
              sx={{
                height: '40px',
                width: '55px',
                color: 'black',
                marginRight: '10px',
              }}
              onClick={() => handleClick()}
            >
              {verified ? (
                <CloseIcon htmlColor="red" />
              ) : (
                <CheckIcon htmlColor="green" />
              )}
            </Button>
          </Grid>
          {weightclasses.map((weight, i) => (
            <WeightCell key={weight} weight={weight} pick={lbs[i]} />
          ))}
          <WeightCell
            weight="WC"
            pick={`${props.verification.wcSeed}-${props.verification.wcWeight}`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VerificationCard;
