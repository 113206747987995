import './App.css';

import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import theme from './components/Theme';
import Page404 from './route/404';
import Admin from './route/Admin';
import AdminAdd from './route/AdminAdd';
import AdminEdit from './route/AdminEdit';
import Login from './route/Login';
import Logout from './route/Logout';
import Main from './route/Main';
import Setup from './route/Setup';
import Verify from './route/Verify';

const App = () => {
  return (
    <div className="App">
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<Main />} />
                <Route path="/admin" element={<Admin />}></Route>
                <Route path="/admin/add" element={<AdminAdd />} />
                <Route path="/admin/edit" element={<AdminEdit />} />
                <Route path="/admin/verify" element={<Verify />} />
                <Route path="/admin/setup" element={<Setup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="*" element={<Page404 />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </RecoilRoot>
    </div>
  );
};

export default App;
