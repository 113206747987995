import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import type { Athlete, Conference, School } from '../../model/models';
import {
  checkAuth,
  createGetAthlete,
  createGetConference,
  createGetSchool,
} from '../../utils/fetch';
import AthleteComponent from './Athlete';
import ConferenceComponent from './Conference';
import SchoolComponent from './School';

const Create = () => {
  const [conferences, setConferences] = useState<Conference[]>();
  const [schools, setSchools] = useState<School[]>();
  const [athletes, setAthletes] = useState<Athlete[]>();
  const [isAuth, setIsAuth] = useState(false);
  const location = useLocation();

  useEffect(() => {
    checkAuth().then((result) => {
      if (result) {
        setIsAuth(true);
      } else {
        const nextPage = new URLSearchParams(location.search).get('next');
        if (nextPage) {
          window.location.href = nextPage;
        } else {
          window.location.href = '/login?next=%2Fadmin%2Fsetup';
        }
      }
    });
  }, [athletes, schools, conferences]);

  useEffect(() => {
    createGetConference().then((result) => {
      if (result && result.data) {
        setConferences(result.data);
      }
    });
    createGetSchool().then((result) => {
      if (result && result.data) {
        setSchools(result.data);
      }
    });
    createGetAthlete().then((result) => {
      if (result && result.data) {
        setAthletes(result.data);
      }
    });
  }, []);

  if (!isAuth) return <div>Loading...</div>;

  return (
    <>
      <h3>Create</h3>
      <h4>Conference</h4>
      <Grid
        container
        direction="column"
        sx={{ width: '350px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        {conferences?.map((conference) => (
          <ConferenceComponent
            conference={conference}
            conferences={conferences}
            setConferences={setConferences}
          />
        ))}
        <ConferenceComponent
          conferences={conferences ?? []}
          setConferences={setConferences}
        />
      </Grid>
      <h4>School</h4>
      <Grid
        container
        direction="column"
        sx={{ width: '450px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        {schools?.map((school) => (
          <SchoolComponent
            school={school}
            schools={schools}
            setSchools={setSchools}
            conferences={conferences ?? []}
          />
        ))}
        <SchoolComponent
          schools={schools ?? []}
          setSchools={setSchools}
          conferences={conferences ?? []}
        />
      </Grid>
      <h4>Athletes</h4>
      <Grid
        container
        direction="column"
        sx={{ width: '550px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        {athletes?.map((athlete) => (
          <AthleteComponent
            athlete={athlete}
            athletes={athletes}
            setAthletes={setAthletes}
            schools={schools ?? []}
          />
        ))}
        <AthleteComponent
          athletes={athletes ?? []}
          setAthletes={setAthletes}
          schools={schools ?? []}
        />
      </Grid>
      {/* <select
        value={newSchool.conferenceId ?? ''}
        onChange={(e) => handleChangeSchool('conferenceId', e)}
        style={{
          padding: 10,
          backgroundColor: '#fbfbfb',
          maxWidth: '80%',
        }}
      >
        {conferences?.map((conference) => (
          <option value={conference.id}>{conference.name}</option>
        ))}
      </select> */}
    </>
  );
};
export default Create;
