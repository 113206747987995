import { atom } from 'recoil';

import type {
  Athlete,
  Board,
  Bracket,
  Brackets,
  Contact,
  FlatBracket,
  Modes,
  Selection,
  Settings,
  Submit,
  Weight,
  WinCondition,
} from './models';

const selectionState = atom({
  key: 'selectionState', // unique ID (with respect to other atoms/selectors)
  default: {
    weights: {
      125: 0,
      133: 0,
      141: 0,
      149: 0,
      157: 0,
      165: 0,
      174: 0,
      184: 0,
      197: 0,
      285: 0,
    },
    wc: { weight: 125, seed: 0 },
  } as Selection, // default value (aka initial value)
});

const selectedWeightState = atom({
  key: 'selectedWeightState', // unique ID (with respect to other atoms/selectors)
  default: 125 as Weight, // default value (aka initial value)
});

const selectedIdState = atom({
  key: 'selectedIdState', // unique ID (with respect to other atoms/selectors)
  default: '' as string, // default value (aka initial value)
});

const bracketState = atom({
  key: 'bracketState',
  default: {} as Bracket,
});

const consolationBracketState = atom({
  key: 'consolidationBracketState',
  default: {} as Bracket,
});

const athletesState = atom({
  key: 'athletesState',
  default: {} as Athlete[],
});

const bracketsState = atom({
  key: 'bracketsState', // unique ID (with respect to other atoms/selectors)
  default: {
    125: null,
    133: null,
    141: null,
    149: null,
    157: null,
    165: null,
    174: null,
    184: null,
    197: null,
    285: null,
  } as Brackets, // default value (aka initial value)
});

const consolationBracketsState = atom({
  key: 'consolidationBracketsState', // unique ID (with respect to other atoms/selectors)
  default: {
    125: null,
    133: null,
    141: null,
    149: null,
    157: null,
    165: null,
    174: null,
    184: null,
    197: null,
    285: null,
  } as Brackets, // default value (aka initial value)
});

const modeState = atom({
  key: 'modeState',
  default: null as Modes,
});

const contactState = atom({
  key: 'contactState',
  default: { first: '', last: '', alt: '' } as Contact,
});

const settingsState = atom({
  key: 'settingsState',
  default: { allowEntry: false, allowView: 0 } as Settings,
});

const submitState = atom({
  key: 'submitState',
  default: null as Submit | null,
});

const boardState = atom({
  key: 'boardState',
  default: {} as Board[],
});

const constraintsState = atom({
  key: 'constraintsState',
  default: false as Boolean,
});

const bracketShowConsolationState = atom({
  key: 'bracketShowConsolationState',
  default: false as Boolean,
});

const flatBracketState = atom({
  key: 'flatBracketState',
  default: {} as FlatBracket,
});

const winConditionsState = atom({
  key: 'winConditionsState',
  default: {} as WinCondition[],
});

const showInstructionsState = atom({
  key: 'showInstructionsState',
  default: true as Boolean,
});

const adminFilterWeightState = atom({
  key: 'adminFilterWeightState',
  default: undefined as Weight | undefined,
});

export {
  adminFilterWeightState,
  athletesState,
  boardState,
  bracketShowConsolationState,
  bracketsState,
  bracketState,
  consolationBracketsState,
  consolationBracketState,
  constraintsState,
  contactState,
  flatBracketState,
  modeState,
  selectedIdState,
  selectedWeightState,
  selectionState,
  settingsState,
  showInstructionsState,
  submitState,
  winConditionsState,
};
