import CasinoIcon from '@mui/icons-material/Casino';
import { Button } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import config from '../config';
import { primaryColor } from '../model/arrays';
import {
  athletesState,
  bracketsState,
  modeState,
  showInstructionsState,
} from '../model/store';
import AthleteCard from './AthleteCard';

const Instructions = () => {
  const weight = 125;
  const mode = useRecoilValue(modeState);
  const br = useRecoilValue(bracketsState)[weight];
  const athletes = useRecoilValue(athletesState);
  const [show, setShow] = useRecoilState(showInstructionsState);

  const bout = br?.round1.find((b) => b.boutA < 8);

  if (!show || !athletes || !athletes[0] || !athletes[0].seed || !bout) {
    return <></>;
  }

  const athletesInWeight = athletes.filter(
    (athlete) => athlete.weight === weight
  );

  if (mode === 'create') {
    return (
      <div
        style={{
          backgroundColor: '#f9f9f9',
          padding: 5,
          borderRadius: 15,
          marginBottom: 25,
          marginRight: 'auto',
          marginLeft: 'auto',
          width: '800px',
          maxWidth: '100%',
          border: '3px solid',
          borderColor: 'black',
          overflowX: 'auto',
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', right: 0, top: 0 }}>
          <Button onClick={() => setShow(false)}>close</Button>
        </div>
        <h3 style={{ marginBottom: 0 }}>Instructions</h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <p>1. Select one of the athletes below.</p>
          </div>
          <div style={{ width: 400, marginLeft: 25, marginRight: 25 }}>
            <AthleteCard
              isA={true}
              bout={bout}
              selected={0}
              mode={mode}
              athletesInWeight={athletesInWeight}
              deactivateClick={true}
              hideWildcard={true}
            />
          </div>
          <div>
            <p>2. The selected athlete looks like this</p>
          </div>
          <div style={{ width: 400 }}>
            <AthleteCard
              isA={true}
              bout={bout}
              selected={bout.boutA}
              mode={mode}
              athletesInWeight={athletesInWeight}
              deactivateClick={true}
              hideWildcard={true}
            />
          </div>
          <div>
            <p>
              3. Do this for all 10 weightclasses. Make sure you meet the
              constraints shown in the bottom right corner.
            </p>
          </div>
          <div>
            <p style={{ display: 'flex', marginBottom: 4 }}>
              4. <b>Wildcard</b>: Select one wildcard, by clicking the symbol
              <CasinoIcon
                style={{
                  color: primaryColor,
                  cursor: 'pointer',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              />
              . This can be any seed greater than {config.WILDCARD_MIN_SEED - 1}
              .
            </p>
          </div>
          <div>
            <p>
              5. After you submitted your bracket, please make your donation to
              get your bracket verified.
            </p>
          </div>
        </div>
      </div>
    );
  }

  const boutWinning = br?.round1.find((b) => b.winner === b.boutA);
  const boutLoser = br?.round1.find((b) => b.winner !== b.boutB);

  return (
    <div
      style={{
        backgroundColor: '#f9f9f9',
        padding: 5,
        borderRadius: 15,
        marginBottom: 25,
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '800px',
        maxWidth: '100%',
        border: '3px solid',
        borderColor: 'black',
        overflowX: 'auto',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <Button onClick={() => setShow(false)}>close</Button>
      </div>
      <h3 style={{ marginBottom: 0 }}>Explanation</h3>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div>
          <p>Your selected athletes are marked like this</p>
        </div>
        <div style={{ width: 400 }}>
          <AthleteCard
            isA={true}
            bout={bout}
            selected={bout.boutA}
            mode={mode}
            athletesInWeight={athletesInWeight}
            deactivateClick={true}
            hideWildcard={true}
          />
        </div>
        {boutWinning ? (
          <>
            <div>
              <p>The winner of a match is marked with a thick outline</p>
            </div>
            <div style={{ width: 400 }}>
              <AthleteCard
                isA={true}
                bout={boutWinning}
                selected={0}
                mode={mode}
                athletesInWeight={athletesInWeight}
                deactivateClick={true}
                hideWildcard={true}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {boutLoser ? (
          <>
            <div>
              <p>Losers are struck through</p>
            </div>
            <div style={{ width: 400 }}>
              <AthleteCard
                isA={false}
                bout={boutLoser}
                selected={0}
                mode={mode}
                athletesInWeight={athletesInWeight}
                deactivateClick={true}
                hideWildcard={true}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <div>
          <p style={{ display: 'flex', marginBottom: 4 }}>
            The selected wildcard is the only one that has this symbol:
            <CasinoIcon
              style={{
                color: primaryColor,
                cursor: 'pointer',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
