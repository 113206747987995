import type { Config } from './model/models';

const config: Config = {
  API_URL: process.env.REACT_APP_API_URL ?? '',
  API_KEY: process.env.REACT_APP_API_KEY ?? '',
  WILDCARD_MIN_SEED: !Number.isNaN(
    parseInt(process.env.REACT_APP_WILDCARD_MIN_SEED!, 10)
  )
    ? parseInt(process.env.REACT_APP_WILDCARD_MIN_SEED!, 10)
    : 8,
  BRACKET_SIZE: parseInt(
    process.env.REACT_APP_BRACKET_SIZE || '33',
    10
  ) as Config['BRACKET_SIZE'],
};

export default config;
