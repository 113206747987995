import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import { useRef, useState } from 'react';

import config from '../../config';
import { weightclasses } from '../../model/arrays';
import type { Athlete, Conference } from '../../model/models';
import {
  createDeleteAthlete,
  createPostAthlete,
  createUpdateAthlete,
} from '../../utils/fetch';

type Props = {
  athlete?: Athlete;
  athletes: Athlete[];
  setAthletes: React.Dispatch<React.SetStateAction<Athlete[] | undefined>>;
  schools: Conference[];
};

const Component = (props: Props) => {
  const defaultAthlete: Athlete = {
    id: 0,
    weight: 125,
    seed: 1,
    firstname: 'First',
    lastname: 'Last',
    schoolId: props.schools?.[0]?.id,
    wins: 0,
    losses: 0,
  };
  const [athlete, setAthlete] = useState<Athlete>(
    props.athlete ?? defaultAthlete
  );
  const firstnameRef = useRef<HTMLSpanElement>(null);
  const lastnameRef = useRef<HTMLSpanElement>(null);
  const winRef = useRef<HTMLSpanElement>(null);
  const lossRef = useRef<HTMLSpanElement>(null);

  function handleClick(ref: React.RefObject<HTMLSpanElement>) {
    if (ref && ref.current) {
      ref.current.setAttribute('contentEditable', 'true');
      ref.current.focus();
    }
  }

  function handleBlur(
    event: React.FocusEvent<EventTarget>,
    field: keyof Athlete,
    ref: React.RefObject<HTMLSpanElement>
  ) {
    if (ref && ref.current) {
      ref.current.setAttribute('contentEditable', 'false');
      const newElement = {
        ...athlete,
        [field]: ref.current.textContent,
      };
      setAthlete(newElement);
      if (props.athlete && newElement[field] !== props.athlete[field]) {
        createUpdateAthlete(newElement);
      }
      const updatedItems = props.athletes.map((item) =>
        item.id === newElement.id ? newElement : item
      );
      props.setAthletes(updatedItems);
    }
  }

  function handleChange(
    event: React.ChangeEvent<HTMLSelectElement>,
    field: keyof Athlete
  ) {
    const newElement = {
      ...athlete,
      [field]: parseInt(event.target.value, 10),
    };

    setAthlete(newElement);
    if (props.athlete) {
      createUpdateAthlete(newElement);
    }
  }

  function handleKeyDown(
    event: React.KeyboardEvent<HTMLSpanElement>,
    ref: React.RefObject<HTMLSpanElement>
  ) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (ref && ref.current) {
        ref.current.blur();
      }
    }
  }

  const availableWeightclasses = weightclasses
    .filter(
      (weight) =>
        props.athletes.filter((a) => a.weight === weight).length <
        config.BRACKET_SIZE
    )
    .map((weight) => <option value={weight}>{weight}</option>);

  const availableSeeds = Array.from(Array(config.BRACKET_SIZE).keys())
    .map((x) => x + 1)
    .filter(
      (seed) =>
        !props.athletes.find(
          (a) => a.seed === seed && a.weight === athlete.weight
        )
    )
    .map((seed) => <option value={seed}>{seed}</option>);

  if (!props.schools || props.schools.length === 0) {
    if (!props.athlete) {
      return <p>Enter schools first before you enter an athlete</p>;
    }
    return <></>;
  }

  if (!props.athlete) {
    if (availableWeightclasses.length === 0 && availableSeeds.length === 0) {
      return <p>all weight classes occupied</p>;
    }
    return (
      <Grid
        item
        key={athlete.id}
        sx={{
          margin: 1,
          border: 1,
          padding: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <select
          value={athlete.weight ?? ''}
          onChange={(e) => handleChange(e, 'weight')}
          style={{
            padding: 5,
            backgroundColor: '#fbfbfb',
            maxWidth: '5rem',
          }}
        >
          {availableWeightclasses}
        </select>
        <select
          value={athlete.seed ?? ''}
          onChange={(e) => handleChange(e, 'weight')}
          style={{
            padding: 5,
            backgroundColor: '#fbfbfb',
            maxWidth: '5rem',
          }}
        >
          {availableSeeds}
        </select>
        <span
          onClick={() => handleClick(firstnameRef)}
          onBlur={(e) => handleBlur(e, 'firstname', firstnameRef)}
          onKeyDown={(e) => handleKeyDown(e, firstnameRef)}
          ref={firstnameRef}
        >
          {athlete.firstname}
        </span>{' '}
        <span
          onClick={() => handleClick(lastnameRef)}
          onBlur={(e) => handleBlur(e, 'lastname', lastnameRef)}
          onKeyDown={(e) => handleKeyDown(e, lastnameRef)}
          ref={lastnameRef}
        >
          {athlete.lastname}
        </span>{' '}
        <select
          value={athlete.schoolId ?? ''}
          onChange={(e) => handleChange(e, 'schoolId')}
          style={{
            padding: 5,
            backgroundColor: '#fbfbfb',
            maxWidth: '5.5rem',
          }}
        >
          {props.schools.map((school) => (
            <option value={school.id}>{school.name}</option>
          ))}
        </select>
        <span>
          <span
            onClick={() => handleClick(winRef)}
            onBlur={(e) => handleBlur(e, 'wins', winRef)}
            onKeyDown={(e) => handleKeyDown(e, winRef)}
            ref={winRef}
          >
            {athlete.wins}
          </span>{' '}
          -{' '}
          <span
            onClick={() => handleClick(lossRef)}
            onBlur={(e) => handleBlur(e, 'losses', lossRef)}
            onKeyDown={(e) => handleKeyDown(e, lossRef)}
            ref={lossRef}
          >
            {athlete.losses}
          </span>
        </span>
        <button
          onClick={() => {
            createPostAthlete(athlete).then((result) => {
              if (result) {
                props.setAthletes(result.data);
                setAthlete(defaultAthlete);
              }
            });
          }}
          disabled={!athlete.schoolId}
        >
          <AddIcon />
        </button>
      </Grid>
    );
  }
  return (
    <Grid
      item
      key={athlete.id}
      sx={{
        margin: 1,
        border: 1,
        padding: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span>
        <span
          style={{
            background: 'black',
            color: 'white',
            padding: 3,
            marginRight: 4,
          }}
        >
          {athlete.weight}
        </span>
        <span
          style={{
            background: 'black',
            color: 'white',
            padding: 3,
          }}
        >
          {athlete.seed}
        </span>
      </span>
      <span
        onClick={() => handleClick(firstnameRef)}
        onBlur={(e) => handleBlur(e, 'firstname', firstnameRef)}
        onKeyDown={(e) => handleKeyDown(e, firstnameRef)}
        ref={firstnameRef}
      >
        {athlete.firstname}
      </span>{' '}
      <span
        onClick={() => handleClick(lastnameRef)}
        onBlur={(e) => handleBlur(e, 'lastname', lastnameRef)}
        onKeyDown={(e) => handleKeyDown(e, lastnameRef)}
        ref={lastnameRef}
      >
        {athlete.lastname}
      </span>{' '}
      <select
        value={athlete.schoolId ?? ''}
        onChange={(e) => handleChange(e, 'schoolId')}
        style={{
          padding: 5,
          backgroundColor: '#fbfbfb',
          maxWidth: '5.5rem',
        }}
      >
        {props.schools.map((school) => (
          <option value={school.id}>{school.name}</option>
        ))}
      </select>
      <span>
        <span
          onClick={() => handleClick(winRef)}
          onBlur={(e) => handleBlur(e, 'wins', winRef)}
          onKeyDown={(e) => handleKeyDown(e, winRef)}
          ref={winRef}
        >
          {athlete.wins}
        </span>{' '}
        -{' '}
        <span
          onClick={() => handleClick(lossRef)}
          onBlur={(e) => handleBlur(e, 'losses', lossRef)}
          onKeyDown={(e) => handleKeyDown(e, lossRef)}
          ref={lossRef}
        >
          {athlete.losses}
        </span>
      </span>
      <button
        onClick={() => {
          if (athlete.id) {
            createDeleteAthlete(athlete.id.toString()).then((result) => {
              if (result) {
                props.setAthletes(result.data);
              }
            });
          }
        }}
      >
        <DeleteIcon />
      </button>
    </Grid>
  );
};

export default Component;
