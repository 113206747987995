import CasinoIcon from '@mui/icons-material/Casino';
import { Button, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { weightclasses } from '../model/arrays';
import type { Weight } from '../model/models';
import { selectedWeightState, selectionState } from '../model/store';

const WeightButton = (props: {
  selection: number;
  weightclassFromArray: Weight;
  selectedWeight: number;
  setSelectedWeight: any;
}) => {
  return (
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <Button
            variant={
              props.selection > 0 &&
              props.weightclassFromArray !== props.selectedWeight
                ? 'contained'
                : 'outlined'
            }
            color={
              props.selection > 0 ||
              props.weightclassFromArray === props.selectedWeight
                ? 'success'
                : 'info'
            }
            onClick={() => {
              props.setSelectedWeight(props.weightclassFromArray as Weight);
            }}
          >
            {props.weightclassFromArray as Weight}
          </Button>
        </Grid>
        <Grid item>
          <i style={{ color: 'gray' }}>
            {props.selection > 0 ? `#${props.selection}` : ''}
          </i>
        </Grid>
      </Grid>
    </Grid>
  );
};

const WildcardButton = (props: {
  selection: { weight: Weight; seed: number };
  setSelectedWeight: any;
}) => {
  return (
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <Button
            variant={props.selection.seed > 0 ? 'contained' : 'outlined'}
            color={props.selection.seed > 0 ? 'success' : 'info'}
            onClick={() => {
              props.setSelectedWeight(props.selection.weight as Weight);
            }}
          >
            WC <CasinoIcon />
          </Button>
        </Grid>
        <Grid item>
          <i style={{ color: 'gray' }}>
            {props.selection.seed > 0
              ? `#${props.selection.seed}/${props.selection.weight}`
              : ''}
          </i>
        </Grid>
      </Grid>
    </Grid>
  );
};

const WeightButtonsView = () => {
  const [selectedWeight, setSelectedWeight] =
    useRecoilState(selectedWeightState);
  const selectedList = useRecoilValue(selectionState);

  useEffect(
    function updateTitle() {
      document.title = `${selectedWeight} lbs weight class`;
    },
    [selectedWeight]
  );

  return (
    <div className="buttons">
      <Grid container direction="row" spacing="3" justifyContent="center">
        {weightclasses.map((weightclassFromArray) => {
          const selection =
            selectedList.weights[
              weightclassFromArray as unknown as keyof typeof selectedList.weights
            ];
          return (
            <WeightButton
              key={weightclassFromArray}
              selection={selection}
              weightclassFromArray={weightclassFromArray}
              selectedWeight={selectedWeight}
              setSelectedWeight={setSelectedWeight}
            />
          );
        })}
        <WildcardButton
          selection={selectedList.wc}
          setSelectedWeight={setSelectedWeight}
        />
      </Grid>
    </div>
  );
};

export default WeightButtonsView;

// source: https://reactjs.org/docs/hooks-rules.html
