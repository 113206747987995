import { Button, CircularProgress, Grid } from '@mui/material';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { primaryColor } from '../model/arrays/colors';
import type { SettingsFieldNames } from '../model/models';
import { settingsState } from '../model/store';
import {
  postCalculateScore,
  postSettings,
  postSimulateFinish,
  postSimulateStart,
} from '../utils/fetch';

type Props = {
  children: React.ReactNode;
  fn: () => void;
  description: string;
};

const Action = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  async function handleFunction() {
    setLoading(true);
    await props.fn();
    setLoading(false);
  }

  return (
    <Grid
      container
      flexDirection={'column'}
      border={1}
      borderColor={primaryColor}
      borderRadius={1}
      padding={1}
      width={'30%'}
      minWidth={'200px'}
    >
      <Grid item>
        <Button
          onClick={() => handleFunction()}
          variant={'outlined'}
          style={{ margin: '8px' }}
        >
          {loading ? <CircularProgress /> : <>{props.children}</>}
        </Button>
      </Grid>
      <Grid item>{props.description}</Grid>
    </Grid>
  );
};

const ControlPanel = () => {
  const [settings, setSettings] = useRecoilState(settingsState);

  function handleClick(field: SettingsFieldNames) {
    const newState = { ...settings };
    if (field === 'allow_entry') {
      postSettings(field, !settings.allowEntry);
      newState.allowEntry = !settings.allowEntry;
    } else if (field === 'hide_unverified_when_locked') {
      postSettings(field, !settings.hideUnverifiedWhenLocked);
      newState.hideUnverifiedWhenLocked = !settings.hideUnverifiedWhenLocked;
    }
    setSettings(newState);
  }

  return (
    <div className="control-panel">
      <h3>Control Panel</h3>
      <Grid container flexDirection={'row'} gap={1} justifyContent={'center'}>
        <Action
          fn={() => handleClick('allow_entry')}
          description={'Lock after tournament started'}
        >
          {settings.allowEntry ? 'Lock Entries' : 'Allow Entries'}
        </Action>
        <Action
          fn={() => handleClick('hide_unverified_when_locked')}
          description="Hide unverified when entries are locked"
        >
          {settings.hideUnverifiedWhenLocked
            ? 'Show unverified'
            : 'Hide unverified'}
        </Action>
        <Action
          fn={() => postCalculateScore()}
          description={'calculate score for all brackets'}
        >
          calculate score
        </Action>
        <Action
          fn={() => postSimulateStart()}
          description={'Warning: Deletes all results'}
        >
          simulate start
        </Action>
        <Action
          fn={() => postSimulateFinish()}
          description={'Fills with random results'}
        >
          simulate finish
        </Action>
      </Grid>
    </div>
  );
};

export default ControlPanel;
