import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  bracketShowConsolationState,
  contactState,
  modeState,
  selectedIdState,
  selectedWeightState,
  selectionState,
} from '../model/store';
import clearLocalStorage from '../utils/clearLocalStorage';

const StartOver = () => {
  const [mode, setMode] = useRecoilState(modeState);
  const resetSelection = useResetRecoilState(selectionState);
  const resetName = useResetRecoilState(contactState);
  const resetSelectedId = useResetRecoilState(selectedIdState);
  const resetSelectedWeight = useResetRecoilState(selectedWeightState);
  const resetBracketShowConsolation = useResetRecoilState(
    bracketShowConsolationState
  );

  function handleClick() {
    if (mode === 'view') {
      resetName();
      resetSelection();
      resetSelectedId();
      resetSelectedWeight();
      setMode(null);
      resetBracketShowConsolation();
    } else {
      clearLocalStorage();
      window.location.reload();
    }
  }

  return (
    <div
      className="floating-button"
      style={{ top: '0.5rem', right: '0.5rem', position: 'fixed' }}
    >
      <Button sx={{ height: 50, width: 50, padding: 0 }} onClick={handleClick}>
        <CloseIcon />
      </Button>
    </div>
  );
};

export default StartOver;
