import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { primaryColor } from '../model/arrays';
import {
  constraintsState,
  contactState,
  selectionState,
  submitState,
} from '../model/store';
import clearLocalStorage from '../utils/clearLocalStorage';
import { postSubmit } from '../utils/fetch';
import { countWeightclassSelected } from '../utils/numbers';

const Submit = () => {
  const selectedList = useRecoilValue(selectionState);
  const [submission, setSubmission] = useRecoilState(submitState);
  const constraints = useRecoilValue(constraintsState);
  const contact = useRecoilValue(contactState);
  const [errorMessage, setErrorMessage] = useState<string>();

  function fetch() {
    // Call the postSubmit function to post the data and get the response
    const res = postSubmit(selectedList, contact);
    res.then(
      (result) => {
        // Check if the result is an error object
        if (result instanceof Error) {
          // Check if the error message includes the HTTP status code 409
          if (result.message.includes('400')) {
            // Handle error for HTTP status code 400
            setErrorMessage('Invalid wild card');
          }
          // Check if the error message includes the HTTP status code 406
          else if (result.message.includes('406')) {
            // Handle error for HTTP status code 406
            setErrorMessage(
              'The bracket you submitted contains invalid elements. Please try again.'
            );
          }
          // Check if the error message includes the HTTP status code 406
          else if (result.message.includes('409')) {
            // Handle error for HTTP status code 406
            setErrorMessage(
              'Looks like someone submitted the same bracket before you did. Please make some changes.'
            );
          }
          // Handle other error cases
          else {
            setErrorMessage('an unknown error occurred');
          }
          return false;
        }
        // setIsLoaded(true);

        // Set the submission data
        setSubmission(result);

        // Clear the local storage
        clearLocalStorage();

        // If the response includes a secret, store it in local storage
        if (result.secret) {
          if (localStorage.getItem('secret')) {
            const oldString = localStorage.getItem('secret');
            localStorage.setItem(
              'secret',
              `${oldString};${localStorage.getItem('secret')}`
            );
          }
          localStorage.setItem('secret', result.secret);
        }

        // Return true if everything is successful
        return true;
      },
      // Handle errors
      (_error) => {
        // setIsLoaded(true);
        return false;
        // setError(error);
      }
    );
  }
  if (submission === null) {
    if (countWeightclassSelected(selectedList) !== 10) {
      return (
        <>
          <Button
            disabled={true}
            onClick={() => fetch()}
            variant="outlined"
            color="info"
            sx={{ paddingLeft: 6, paddingRight: 6, margin: 1 }}
          >
            Pick an athlete for all 10 weight classes
          </Button>
        </>
      );
    }
    if (!(selectedList.wc.seed > 0)) {
      return (
        <Button
          disabled={true}
          onClick={() => fetch()}
          variant="outlined"
          color="info"
          sx={{ paddingLeft: 6, paddingRight: 6, margin: 1 }}
        >
          Pick one wild card
        </Button>
      );
    }
    return (
      <>
        <Button
          disabled={!constraints}
          onClick={() => fetch()}
          variant="outlined"
          color="info"
          sx={{ paddingLeft: 6, paddingRight: 6, margin: 1 }}
        >
          {!constraints ? 'Constraints not met' : 'Enter!'}
        </Button>
        {errorMessage ? (
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                backgroundColor: '#242424',
                color: 'white',
                padding: 10,
                maxWidth: '800px',
                borderRadius: 5,
                margin: 0,
              }}
            >
              {errorMessage}
            </p>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
  return (
    <Grid container className="submitted" sx={{ justifyContent: 'center' }}>
      <Box
        sx={{
          width: '40rem',
          borderRadius: '1rem',
          border: 2,
          borderColor: primaryColor,
          bgcolor: 'lightgray',
          padding: 2,
          margin: 2,
        }}
        // sx={{ backgroundColor: 'secondary.dark', padding: 2}}
      >
        <p>You entered the competition.</p>
        <p>Please write down the following code.</p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigator.clipboard.writeText(submission.id)}
        >
          {submission.id} 📋
        </Button>
        <p>
          When you pay your entry fee, please include this code in the payment
          notes. This makes it easier for us to confirm your entry.
        </p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          sx={{ margin: 1 }}
        >
          Done
        </Button>
      </Box>
    </Grid>
  );
};

export default Submit;
