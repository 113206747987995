import config from '../config';
import type { Selection, Weight } from '../model/models';

export function ClickedAthlete(
  weightclass: Weight,
  seed: number,
  bout: number,
  selectedList: Selection,
  setSelectedList: any
) {
  if (
    seed === 0 ||
    (config.BRACKET_SIZE === 33 && bout > 180) ||
    (config.BRACKET_SIZE === 16 && (bout < 180 || bout > 260))
  ) {
    return;
  }
  const newSelectedList = {
    ...selectedList,
    weights: { ...selectedList.weights, [weightclass]: seed },
  } as Selection;
  setSelectedList(newSelectedList);
  localStorage.setItem(
    `selection${weightclass.toString()}`,
    newSelectedList.weights[weightclass].toString()
  );
  // Scroll to top
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}
