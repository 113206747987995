import CheckIcon from '@mui/icons-material/Check';
import TocIcon from '@mui/icons-material/Toc';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import type { Board, Board as BoardType, Settings } from '../model/models';
import {
  boardState,
  contactState,
  modeState,
  selectionState,
  settingsState,
} from '../model/store';
// import Submission from "../controller/GetSubmit";
import { epochToLocale } from '../utils/epochToHuman';
import {
  checkAuth,
  fetchBoard,
  fetchSubmit,
  fetchWagerAllowed,
} from '../utils/fetch';
import Details from './Details';

const Row = (props: {
  board: Board;
  allowView: boolean;
  allowed?: string[];
  setShowDetails: any;
  loadView: any;
}) => {
  return (
    <TableRow key={props.board.id}>
      <TableCell>
        {props.board.firstname} {props.board.lastname}
      </TableCell>
      <TableCell>{props.board.score}</TableCell>
      <TableCell>
        <Button
          size="small"
          color="primary"
          disabled={!props.allowView}
          onClick={() => props.setShowDetails(props.board)}
        >
          <TocIcon />
        </Button>
      </TableCell>
      <TableCell>{props.board.avgSeed}</TableCell>
      <TableCell>{epochToLocale(props.board.dateAddedTimestamp)}</TableCell>
      <TableCell align="center">
        {props.board.verified ? <CheckIcon /> : <></>}
      </TableCell>
      <TableCell align="center">
        <Button
          size="small"
          color="primary"
          disabled={
            !(props.allowed && props.allowed.indexOf(props.board.id) !== -1)
          }
          onClick={() => props.loadView(props.board)}
        >
          <VisibilityIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
};

const Body = (props: {
  boards: BoardType[];
  loadView: any;
  setShowDetails: any;
  settings: Settings;
  allowed?: string[];
  auth?: boolean;
}) => {
  if (Array.isArray(props.boards)) {
    const allowView =
      props.auth ||
      props.settings.allowView === 1 ||
      (props.settings.allowView === 2 && props.settings.allowEntry === false);

    const sortedBoards = !props.settings.allowEntry
      ? props.boards.slice().sort((a, b) => b.score - a.score)
      : props.boards
          .slice()
          .sort((a, b) => b.dateAddedTimestamp - a.dateAddedTimestamp);

    return (
      <TableBody>
        {sortedBoards.map((board) => (
          <Row
            key={board.id}
            board={board}
            allowView={allowView}
            allowed={props.allowed}
            setShowDetails={props.setShowDetails}
            loadView={props.loadView}
          />
        ))}
      </TableBody>
    );
  }
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center">Loading...</TableCell>
      </TableRow>
    </TableBody>
  );
};

const BoardView = () => {
  const [boards, setBoards] = useRecoilState(boardState);
  const setMode = useSetRecoilState(modeState);
  const setName = useSetRecoilState(contactState);
  const [selectedId, setSelectedId] = useState('');
  const setSelectedList = useSetRecoilState(selectionState);
  const settings = useRecoilValue(settingsState);
  const [auth, setAuth] = useState(false);
  const [showDetails, setShowDetails] = useState<Board | null>(null);
  const [allowed, setAllowed] = useState<string[]>();

  useEffect(() => {
    fetchBoard().then(
      (items) => {
        // setIsLoaded(true);
        setBoards(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
    checkAuth().then(
      (resp) => {
        // setIsLoaded(true);
        setAuth(resp);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
    fetchWagerAllowed().then(
      (items) => {
        // setIsLoaded(true);
        setAllowed(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    if (selectedId.length === 0) {
      return;
    }
    setMode('view');
    fetchSubmit(selectedId).then(
      (items) => {
        // setIsLoaded(true);
        setSelectedList(items.selection);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, [selectedId]);

  function loadView(board: BoardType) {
    setSelectedId(board.id);
    setName({ first: board.firstname, last: board.lastname });
    return null;
  }

  return (
    <div className="leaderboard">
      <h2>Leaderboard</h2>
      {auth ? <p>You're here with admin permissions</p> : <></>}
      {showDetails ? (
        <Details
          isOpen={true}
          closePopup={() => setShowDetails(null)}
          board={showDetails}
        />
      ) : (
        <></>
      )}
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: 1080,
          marginLeft: 'auto',
          marginRight: 'auto',
          overflow: 'auto',
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Avg. Seed</TableCell>
              <TableCell>Date</TableCell>
              <TableCell width="20" align="center">
                Verified
              </TableCell>
              <TableCell width="20" align="center">
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <Body
            boards={boards}
            loadView={loadView}
            setShowDetails={setShowDetails}
            settings={settings}
            allowed={allowed}
            auth={auth}
          />
        </Table>
      </TableContainer>
    </div>
  );
};

export default BoardView;
