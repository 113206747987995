import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import BoardView from '../components/BoardView';
import Bracket from '../components/Bracket';
import Constraints from '../components/Constraints';
import { ModeCookies, NameCookies } from '../components/Cookies';
import CreateBracketView from '../components/CreateBracket';
import Locked from '../components/Locked';
import StartOver from '../components/StartOver';
import {
  athletesState,
  bracketsState,
  consolationBracketsState,
  modeState,
  settingsState,
} from '../model/store';
import {
  fetchAllSettings,
  fetchAthlete,
  fetchBrackets,
  fetchConsolationBracket,
} from '../utils/fetch';

const Main = () => {
  const mode = useRecoilValue(modeState);
  const [settings, setSettings] = useRecoilState(settingsState);
  const [brackets, setBrackets] = useRecoilState(bracketsState);
  const setConsolationBrackets = useSetRecoilState(consolationBracketsState);
  const [athletes, setAthletes] = useRecoilState(athletesState);

  document.title = 'Redmen Bracket';
  useEffect(() => {
    fetchAllSettings().then(
      // setIsLoaded(true);
      (result) => {
        setSettings(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchBrackets().then(
      (result) => {
        // setIsLoaded(true);
        setBrackets(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchConsolationBracket().then(
      (result) => {
        // setIsLoaded(true);
        setConsolationBrackets(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchAthlete().then(
      (items) => {
        // setIsLoaded(true);
        setAthletes(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  if (mode === 'create') {
    return (
      <>
        <StartOver />
        <Constraints />
        {brackets !== undefined && athletes !== undefined ? <Bracket /> : <></>}
      </>
    );
  }
  if (mode === 'view') {
    return (
      <>
        <ModeCookies />
        <NameCookies />
        <StartOver />
        {brackets !== undefined && athletes !== undefined ? <Bracket /> : <></>}
        <BoardView />
      </>
    );
  }
  // Undecided
  if (settings.allowEntry === true) {
    return (
      <>
        <ModeCookies />
        <NameCookies />
        <CreateBracketView />
        <BoardView />
      </>
    );
  }
  return (
    <>
      <ModeCookies />
      <NameCookies />
      <Locked />
      <BoardView />
    </>
  );
};

export default Main;
