import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import type { Bracket } from '../model/models';
// import { KeypressSwitchWeight } from "../controller/keypress";
import {
  bracketShowConsolationState,
  bracketsState,
  bracketState,
  consolationBracketsState,
  consolationBracketState,
  contactState,
  modeState,
  selectedWeightState,
} from '../model/store';
import BracketSwitch from './BracketSwitch';
import BracketView from './BracketView';
import ConsolationView from './ConsolationView';
import { WeightCookies } from './Cookies';
import Instructions from './Instructions.';
import Submit from './Submit';
import WeightButtonsView from './WeightButtons';

const BracketComponent = () => {
  const setBracket = useSetRecoilState(bracketState);
  const [consolationBracket, setConsolationBracket] = useRecoilState(
    consolationBracketState
  );
  const weightclass = useRecoilValue(selectedWeightState);
  const mode = useRecoilValue(modeState);
  const bracketShowConsolation = useRecoilValue(bracketShowConsolationState);
  // const [items, setItems] = useState<Bracket[]>([]);
  const brackets = useRecoilValue(bracketsState);
  const consolationBrackets = useRecoilValue(consolationBracketsState);

  useEffect(() => {
    if (brackets !== null) {
      setBracket(brackets[weightclass] as unknown as Bracket);
    }
  }, [weightclass]);

  useEffect(() => {
    if (consolationBracket !== null) {
      setConsolationBracket(
        consolationBrackets[weightclass] as unknown as Bracket
      );
    }
  }, [weightclass]);

  const BracketTitle = () => {
    const name = useRecoilValue(contactState);

    return (
      <div className="bracket-title">
        <h2>{name.first}'s bracket</h2>
      </div>
    );
  };

  if (mode === 'create') {
    return (
      <div className="bracket">
        <BracketTitle />
        <WeightCookies />
        {/* <KeypressSwitchWeight /> */}
        <Instructions />
        <WeightButtonsView />
        <Submit />
        <BracketView />
      </div>
    );
  }
  return (
    <div className="bracket">
      <BracketTitle />
      {/* <KeypressSwitchWeight /> */}
      <Instructions />
      <WeightButtonsView />
      <BracketSwitch />
      {!bracketShowConsolation ? <BracketView /> : <ConsolationView />}
    </div>
  );
};

export default BracketComponent;
